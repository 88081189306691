import React from "react";

export default function Portfolio() {
  return (
    <section className="portfolio--page">
      <div className="portfolio--main">
        <p className="comingSoon">COMING SOON...</p>
      </div>
    </section>
  );
}
